<template>
  <div>
    
    <!-- Affichage pour la page "My mailing lists" -->
    <base-table
      v-if="(this.$route.name != 'Dashboard')"
      :data="table"
      thead-classes="text-primary"
    >
      <template slot-scope="{row}">
        <td v-on:click="($store.state.ml_event_change = true)">
          <v-checkbox
            v-model="row.sub"
            :class="isMobile()?'':'mx-4'"
            :disabled="row.title=='li-gricad-calcul-announces' ? true : false"
          />
        </td>
        <td>
          <div :class="isMobile()?'':'title'">
            {{ row.title }}
          </div>
        </td>
        <td class="text-muted">
          <div :class="isMobile()?'':'title font-weight-light'">
            {{ row.description }}
          </div>
        </td>
      </template>
    </base-table>

    <!-- Affichage pour le Dashboard -->
    <base-table
      v-if="(this.$route.name == 'Dashboard')"
      :data="table"
      thead-classes="text-primary"
    >
      <template slot-scope="{row}">
        <td v-if="row.sub">
          <div :class="isMobile()?'':'title'">
            {{ row.title }}
          </div>
        </td>
        <td class="text-muted" v-if="row.sub">
          <div :class="isMobile()?'':'title font-weight-light'">
            {{ row.description }}
          </div>
        </td>
      </template>
    </base-table>
  </div>
</template>

<script>
  import { BaseTable } from '@/components'
  export default {
    components: {
      BaseTable,
    },
    data: () => ({
    }),
    computed: {
      table () {
        return this.$store.state.mailingList
      },
    },
    methods: {
      isMobile () {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true
        } else {
          return false
        }
      },
    },
  }
</script>
